import getDesktopDownloadUrl from '../utils/desktop-dowload-url';
import {
  DEFAULT_DE_LOCALE,
  DEFAULT_ES_LOCALE,
  DEFAULT_FR_LOCALE,
  DEFAULT_KO_LOCALE,
  DEFAULT_LOCALE,
  ITUNES_APP_LINK,
  LANGUAGE_NAMES,
  PLAY_STORE_APP_LINK,
} from '../constants';
import { createSelector } from 'reselect';
import { splitLocale } from '../lib/locale-utils';
import { selectPlayerIsPlayingAd } from './player';
import { selectShouldPlayPreviews } from './features';

export function selectUserAgent(state) {
  return state.client.useragent;
}

export function selectLocale(state) {
  return state.client.locale;
}

export function selectIsI18nEnabled(state) {
  return state.client.supportedLocales.length > 1;
}

export function selectIsMobile(state) {
  return state.client.userAgentInfo.isMobile;
}

export function selectIsDesktop(state) {
  return state.client.userAgentInfo.isDesktop;
}

export function selectIsAndroid(state) {
  return state.client.userAgentInfo.isAndroid;
}

export function selectIsIOS(state) {
  return state.client.userAgentInfo.isiPhone;
}

export function selectIsWindows(state) {
  return state.client.userAgentInfo.isWindows;
}

export function selectIsMac(state) {
  return state.client.userAgentInfo.isMac;
}

export function selectIsFirefox(state) {
  return state.client.userAgentInfo.isFirefox;
}

export function selectIsChrome(state) {
  return state.client.userAgentInfo.isChrome;
}

export function selectChrome(state) {
  return state.client.ui.chrome;
}

export function selectSupportedLocales(state) {
  return state.client.supportedLocales;
}

export function selectIsSupportedLocale(state, locale) {
  return selectSupportedLocales(state).indexOf(locale) !== -1;
}

export function selectIsConnected(state) {
  return state.client.connected;
}

export const selectSupportedLanguages = createSelector(
  [selectLocale, selectSupportedLocales],
  (geoDetectedLocale, supportedLocales) => {
    const defaultLocales = {
      en: DEFAULT_LOCALE,
      de: DEFAULT_DE_LOCALE,
      fr: DEFAULT_FR_LOCALE,
      es: DEFAULT_ES_LOCALE,
      ko: DEFAULT_KO_LOCALE,
    };

    const supportedLanguages = supportedLocales.reduce((result, locale) => {
      const code = splitLocale(locale).language;
      const preferredLocale =
        geoDetectedLocale.indexOf(code) !== -1 ? geoDetectedLocale : defaultLocales[code];

      result[code] = result[code] || {
        code,
        preferredLocale,
        title: LANGUAGE_NAMES[code],
      };

      return result;
    }, {});

    return Object.values(supportedLanguages);
  }
);

export function selectPlaybackIsUsingAAC(state) {
  return state.client.playbackUsingAAC;
}

export function selectIsLoadingAudio(state) {
  return state.client.isLoadingAudio;
}

export function selectSupportsPlayback(state) {
  return state.client.supportsPlayback;
}

export function selectSupportsLossless(state) {
  return state.client.supportsLossless;
}

export function selectEncryptionEnabled(state) {
  return state.client.encryptedPlayback;
}

export function selectDeviceId(state) {
  return state.client.deviceId;
}

export function selectElectronClientVersion(state) {
  return state.client.versions.electron;
}

export function selectWebappClientVersion(state) {
  return state.client.versions.web;
}

export function selectSignupUserAgent(state, isElectron) {
  if (isElectron) {
    return `desktop v${selectElectronClientVersion(state)}`;
  }
  return `web v${selectWebappClientVersion(state)}`;
}

export function selectRecentlyPlayedItems(state) {
  return state.recentlyPlayed.entities;
}

export function selectCurrentRoutePath(state) {
  return state.routing.locationBeforeTransitions.pathname;
}

export function selectRecentlyPlayedEnabled(state) {
  return state.client.recentlyPlayedEnabled;
}

export function selectUserAgentInfo(state) {
  return state.client.userAgentInfo;
}

export function selectCurrentClientDesktopDownloadUrl(state) {
  const electronVersion = selectElectronClientVersion(state);
  const isMac = selectIsMac(state);
  const isWindows = selectIsWindows(state);

  return getDesktopDownloadUrl(electronVersion, isMac, isWindows);
}

export const selectClientPlatformUrls = createSelector(
  [state => selectElectronClientVersion(state)],
  electronVersion => {
    const macDownloadUrl = getDesktopDownloadUrl(electronVersion, true, false);
    const windowsDownloadUrl = getDesktopDownloadUrl(electronVersion, false, true);
    return [
      {
        name: 'macOS',
        url: macDownloadUrl,
      },
      {
        name: 'Windows',
        url: windowsDownloadUrl,
      },
      {
        name: 'iOS',
        url: ITUNES_APP_LINK,
      },
      {
        name: 'Android',
        url: PLAY_STORE_APP_LINK,
      },
    ];
  }
);

export function selectPersonalPlaylistToAddState(state) {
  return state.client.ui.personalPlaylistModals.addToPlaylistModal;
}

export function selectPersonalPlaylistToEdit(state) {
  return state.client.ui.personalPlaylistModals.editPlaylistModal.playlist;
}

export function selectPersonalPlaylistToDelete(state) {
  return state.client.ui.personalPlaylistModals.deletePlaylistModal.playlist;
}

export function selectClientName(state) {
  // Order is important, macOS will be true when iOS is true, but not vice-versa
  if (selectIsIOS(state)) {
    return 'iOS';
  }

  if (selectIsAndroid(state)) {
    return 'Android';
  }

  if (selectIsWindows(state)) {
    return 'Windows';
  }

  if (selectIsMac(state)) {
    return 'macOS';
  }

  return 'Windows'; // default to windows
}

export function selectClientDownloadUrl(state) {
  const clientName = selectClientName(state);
  const platformUrls = selectClientPlatformUrls(state);
  const platform = platformUrls.find(item => item.name === clientName);

  return platform.url;
}

export function selectClientCountry(state) {
  return state.client.country;
}

export function selectClientIP(state) {
  return state.client.ip;
}

export function selectIsGeoBlockedCountry(state) {
  return state.client.shouldBlock;
}

export function selectCurrency(state) {
  return state.client.currency;
}

export function selectSepaIsSupported() {
  // Temporarily disabled on November 2020
  // https://idagio.slack.com/archives/C04RFTRT5/p1605692014002500
  return false;
}

export function selectAuthApiBase(state) {
  return state.client.urls.authApiBase;
}

export function selectFonts(state) {
  return state.client.fonts;
}

export function selectBaseUrl(state) {
  return state.client.urls.baseUrl;
}

export function selectTopDomain(state) {
  return state.client.urls.topDomain;
}

export function selectSegmentCdnHost(state, options = { noProtocol: false }) {
  const url = state.client.urls.segmentCdnHost;
  if (options.noProtocol) {
    return url.replace(/(^\w+:|^)\/\//, '');
  }
  return url;
}

export function selectEasyConnectSdkOriginUrl(state) {
  return state.client.urls.easyConnectSdkUrlOrigin;
}

export function selectEasyConnectPartnerId(state) {
  return state.client.easyConnectPartnerId;
}

export function selectAdobeApiKey(state) {
  return state.client.adobeApiKey;
}

export function selectSegmentWriteKey(state) {
  return state.client.segmentWriteKey;
}

export function selectTheme(state) {
  return state.client.ui.theme;
}

export function selectAutoplay(state) {
  return state.client.ui.autoplay;
}

export function selectUrlToFonts(state) {
  return state.client.fonts.url;
}

export function selectPlayerAudioQualityDialogIsOpen(state) {
  return state.client.ui.playerAudioQualityDialogIsOpen;
}

export function selectPlayerExternalDeviceDialogIsOpen(state) {
  return state.client.ui.playerExternalDeviceDialogIsOpen;
}

export function selectPlayerBarInfoHidden(state) {
  const isPlayingAd = selectPlayerIsPlayingAd(state);
  return !isPlayingAd && state.client.ui.playerbarInfoHidden;
}

export function selectShouldShowPreviewsModal(state) {
  return selectShouldPlayPreviews(state) && state.client.ui.previewsModal.showingsNumber < 2;
}

export function selectPageTrackingContext(state) {
  return state.client.ui.pageTrackingContext;
}

export function selectModalType(state) {
  return state.client.ui.modalType;
}

export function selectHttpRequestPostData(state) {
  return state.client.httpRequest.postData;
}

export function selectIsInApp(state) {
  return state.client.isInApp;
}

export function selectCookiePreferences(state) {
  return state.client.cookiePreferences;
}

export function selectTicketPurchaseIsDisabled(state) {
  return state.client.ticketPurchaseIsDisabled;
}

export function selectCaptchaType(state) {
  return state.client.captchas.captchaType;
}

export function selectCaptchaSiteKey(state) {
  return state.client.captchas.captchaSiteKey;
}
